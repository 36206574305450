/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";
import SubmitLoader from "components/SubmitLoader";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";




function AddConsultants() {
  const notificationAlert = React.useRef();
  const [loginFullName, setloginFullName] = React.useState("");
  const [loginEmail, setloginEmail] = React.useState("");
  const [userRole, setuserRole] = React.useState("2");
  const [loginFullNameState, setloginFullNameState] = React.useState("");
  const [loginEmailState, setloginEmailState] = React.useState("");
  const registerUser = process.env.REACT_APP_API_URL + "/add-user";
  const loggedUser = JSON.parse(localStorage.getItem('current_users'));
  const [showLoader, setShowLoader] = React.useState(false);
  let history = useHistory();
  const addUser = () => {
    if (loginFullNameState === "") {
      setloginFullNameState("has-danger");
    }
    if (loginEmailState === "") {
      setloginEmailState("has-danger");
    }
    if ((loginEmailState !== "has-danger" && loginFullNameState !== "has-danger") && (loginEmailState !== "" && loginFullNameState !== "")) {
      setShowLoader(true);
      var data = {
        "fullname": loginFullName,
        "email": loginEmail,
        "role": userRole
      }

      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(registerUser, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (!data.success) {
            notify(data.message, 3, notificationAlert);
          } else {
            notify(data.message, 2, notificationAlert);
            setTimeout(function () { history.push('/admin/all-consultants') }, 3000);
          }
          setShowLoader(false);
        });
    }

  };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  return (
    <>
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
            <Card className="card-stats">
              <CardHeader>
                <CardTitle tag="h4">Add Users</CardTitle>
                <Link to="/admin/all-consultants" className="btn btn-primary float-right">Back to List</Link>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" xs="12">
                    <FormGroup className={`has-label ${loginFullNameState}`}>
                      <label>Full Name *</label>
                      <Input
                        name="fullname"
                        type="text"
                        onChange={(e) => {
                          if (!verifyLength(e.target.value, 1)) {
                            setloginFullNameState("has-danger");
                          } else {
                            setloginFullNameState("has-success");
                          }
                          setloginFullName(e.target.value);
                        }}
                      />
                      {loginFullNameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${loginEmailState}`}>
                      <label>Email Address *</label>
                      <Input
                        name="email"
                        type="email"
                        onChange={(e) => {
                          if (!verifyEmail(e.target.value)) {
                            setloginEmailState("has-danger");
                          } else {
                            setloginEmailState("has-success");
                          }
                          setloginEmail(e.target.value);
                        }}
                      />
                      {loginEmailState === "has-danger" ? (
                        <label className="error">
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>
                    <Label>Pleae Select Role for the Consultants</Label>
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="1"
                          id="exampleRadios11"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Administrator <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultChecked
                          defaultValue="2"
                          id="exampleRadios12"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Support Admin staff <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio inline">
                      <Label check>
                        <Input
                          defaultValue="3"
                          id="exampleRadios13"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Support  <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio inline">
                      <Label check for="exampleRadios14">
                        <Input
                          defaultValue="4"
                          id="exampleRadios14"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Remote Sales <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio inline">
                      <Label check for="exampleRadios15">
                        <Input
                          defaultValue="5"
                          id="exampleRadios15"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Sales Consultant  <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio inline">
                      <Label check for="exampleRadios16">
                        <Input
                          defaultValue="6"
                          id="exampleRadios16"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Corporate Sales <span className="form-check-sign" />
                      </Label>
                    </div>

                    <div className="form-check-radio inline">
                      <Label check for="exampleRadios17">
                        <Input
                          defaultValue="7"
                          id="exampleRadios17"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Client Relationship Manager <span className="form-check-sign" />
                      </Label>
                    </div>


                    <div className="form-check-radio inline">
                      <Label check for="exampleRadios18">
                        <Input
                          defaultValue="8"
                          id="exampleRadios18"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Client Relationship Manager Individual <span className="form-check-sign" />
                      </Label>
                    </div>

                    <div className="form-check-radio inline">
                      <Label check for="exampleRadios19">
                        <Input
                          defaultValue="10"
                          id="exampleRadios19"
                          name="role"
                          type="radio"
                          onChange={(e) => {
                            setuserRole(e.target.value);
                          }}
                        />
                        Client Development Manager <span className="form-check-sign" />
                      </Label>
                    </div>
                    
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={addUser}>
                  Add Users
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddConsultants;
