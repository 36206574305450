/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import ClientProducts from "components/Leads/ClientProducts.js";
import NotificationAlert from "react-notification-alert";
import ActivityList from "components/Leads/ActivityList";
import AddDoc from "components/Leads/AddDoc";
import { notify } from "components/functions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
function EditLead(props) {
  const notificationAlert = React.useRef();
  const [verticalTabs, setVerticalTabs] = React.useState("personal-details");
  const [loadingData, setLoadingData] = React.useState(true);
  const [ClientTitle, setClientTitle] = useState("");
  const [ClientFirstName, setClientFirstName] = useState("");
  const [ClientLastName, setClientLastName] = useState("");
  const [BirthDay, setBirthDay] = useState("");
  const [BirthMonth, setBirthMonth] = useState("");
  const [BirthYear, setBirthYear] = useState("");
  const [FollowUpDay, setFollowUpDay] = useState("");
  const [FollowUpMonth, setFollowUpMonth] = useState("");
  const [FollowUpYear, setFollowUpYear] = useState("");
  const [ClientGender, setClientGender] = useState("");
  const [ClientNationality, setClientNationality] = useState("");
  const [DoNotCall, setDoNotCall] = useState("");
  const [PensionOpportunity, setPensionOpportunity] = useState("");
  const [PrimaryCC, setPrimaryCC] = useState("");
  const [PrimaryPhone, setPrimaryPhone] = useState("");
  const [SecondaryCC, setSecondaryCC] = useState("");
  const [SecondaryPhone, setSecondaryPhone] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [ClientOtherEmail, setClientOtherEmail] = useState("");
  const [ClientAddress, setClientAddress] = useState("");
  const [ClientLocation, setClientLocation] = useState("");
  const [countryOptions, setcountryOptions] = useState([
    { value: "", label: "Country", isDisabled: true },
  ]);
  const [dialCodeOptions, setdialCodeOptions] = useState([
    { value: "", label: "Dial Code", isDisabled: true },
  ]);
  const [ClientId, setClientId] = useState([]);
  const [PremiumType, setPremiumType] = useState("");
  const [PremiumTypeState, setPremiumTypeState] = useState("");
  const [fromDashboard, setfromDashboard] = useState(false);
  const params = useParams();
  const getLeadUrl = process.env.REACT_APP_API_URL + "/get-lead/" + params.id;
  const updateClientUrl = process.env.REACT_APP_API_URL + "/update-client";
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const { opportunitiesStatus } = props.location;
  useEffect(() => {
    if (opportunitiesStatus) {
      localStorage.setItem("opportunities_Status", opportunitiesStatus);
    }
  }, [opportunitiesStatus]);

  var opportunities_Status_value = localStorage.getItem("opportunities_Status");

  const titleOptions = [
    {
      value: "",
      label: "Title",
      isDisabled: true,
    },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
  ];
  const genderOptions = [
    { value: "", label: "Gender", isDisabled: true },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  let dayOptions = [];
  let monthOptions = [];
  let yearOptions = [];

  let followYearOptions = [];
  const date = new Date();

  for (let index = 1; index <= 31; index++) {
    dayOptions.push({ value: index, label: index });
  }
  for (let index = 1; index <= 12; index++) {
    monthOptions.push({ value: index, label: index });
  }
  for (let index = date.getFullYear(); index >= 1901; index--) {
    yearOptions.push({ value: index, label: index });
  }
  for (
    let index = date.getFullYear();
    index <= date.getFullYear() + 10;
    index++
  ) {
    followYearOptions.push({ value: index, label: index });
  }
  if (fromDashboard) {
    notify("Opportunity created Successfully.", 2, notificationAlert);
  }
  useEffect(() => {
    async function getLead() {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + loggedUser.token);
      fetch(getLeadUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            // console.log("qwerty123", data);
            setClientTitle(
              data.client.title !== null
                ? { value: data.client.title, label: data.client.title }
                : { value: "", label: "Title", isDisabled: true }
            );
            setClientFirstName(data.client["first-name"]);
            setClientLastName(data.client["last-name"]);
            setBirthDay(
              data.client["day-birth"] !== null
                ? {
                    value: data.client["day-birth"],
                    label: data.client["day-birth"],
                  }
                : { value: "DD", label: "", isDisabled: true }
            );
            setBirthMonth(
              data.client["month-birth"] !== null
                ? {
                    value: data.client["month-birth"],
                    label: data.client["month-birth"],
                  }
                : { value: "", label: "MM", isDisabled: true }
            );
            setBirthYear(
              data.client["year-birth"] !== null
                ? {
                    value: data.client["year-birth"],
                    label: data.client["year-birth"],
                  }
                : { value: "", label: "YYYY", isDisabled: true }
            );
            setFollowUpDay(
              data.client["followup-day"] !== null
                ? {
                    value: data.client["followup-day"],
                    label: data.client["followup-day"],
                  }
                : { value: "", label: "DD", isDisabled: true }
            );
            setFollowUpMonth(
              data.client["followup-month"] !== null
                ? {
                    value: data.client["followup-month"],
                    label: data.client["followup-month"],
                  }
                : { value: "", label: "MM", isDisabled: true }
            );
            setFollowUpYear(
              data.client["followup-year"] !== null
                ? {
                    value: data.client["followup-year"],
                    label: data.client["followup-year"],
                  }
                : { value: "", label: "YYYY", isDisabled: true }
            );
            setClientGender({
              value: data.client.gender,
              label: data.client.gender,
            });
            setClientLocation({
              value: data.client.location,
              label: data.client.location,
            });
            setClientNationality({
              value: data.client.nationality,
              label: data.client.nationality,
            });
            setDoNotCall(data.client["do-not-call"]);
            setPensionOpportunity(data.client["pension-opportunity"]);
            setPrimaryCC(data.client["country-code"]);
            setPrimaryPhone(data.client["phone-number"]);
            setSecondaryCC(data.client["second-code"]);
            setSecondaryPhone(data.client["second-number"]);
            setClientEmail(data.client.email);
            setClientOtherEmail(data.client["second-email"]);
            setClientAddress(data.client.address);
            setcountryOptions(data.countries);
            setdialCodeOptions(data.dial_codes);
            setClientId(data.client.id);
            setPremiumType(
              data.client["premium_type"] !== null
                ? {
                    value: data.client["premium_type"],
                    label: data.client["premium_type"],
                  }
                : { value: "", label: "", isDisabled: true }
            );
            setLoadingData(false);
          } else {
          }
        });
    }
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getLead();
    }
  }, []);

  const updateClient = () => {
    var data = {
      title: ClientTitle.value,
      first_name: ClientFirstName,
      last_name: ClientLastName,
      day: BirthDay.value,
      month: BirthMonth.value,
      year: BirthYear.value,
      followup_day: FollowUpDay.value,
      followup_month: FollowUpMonth.value,
      followup_year: FollowUpYear.value,
      gender: ClientGender.value,
      nationality: ClientNationality.value,
      do_not_call: DoNotCall,
      pension_opportunity: PensionOpportunity,
      country_code: PrimaryCC,
      phone: PrimaryPhone,
      second_code: SecondaryCC,
      second_phone: SecondaryPhone,
      email: ClientEmail,
      second_email: ClientOtherEmail,
      address: ClientAddress,
      location: ClientLocation.value,
      client_id: params.id,
    };

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    fetch(updateClientUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        if (!data.success) {
          notify(data.message, 3, notificationAlert);
        } else {
          notify(data.message, 2, notificationAlert);
        }
      });
  };

  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, fieldState) => {
    if (value.length >= 1) {
      fieldState("has-success");
    } else {
      fieldState("has-danger");
    }
  };
  const getSelectValue = (id, arr) => {
    return arr.filter((item) => item.value === id);
  };
  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
            {loadingData ? (
              <p>Loading Please wait...</p>
            ) : (
              <Card>
                <CardHeader>
                  <h5></h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="2" md="5" sm="4" xs="6">
                      <div className="nav-tabs-navigation verical-navs p-0">
                        <div className="nav-tabs-wrapper">
                          <Nav
                            className="flex-column nav-stacked"
                            role="tablist"
                            tabs
                          >
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "personal-details"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  setVerticalTabs("personal-details")
                                }
                              >
                                Personal Details
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "products" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("products")}
                              >
                                Products
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "activities" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("activities")}
                              >
                                Activities
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                data-toggle="tab"
                                href="#pablo"
                                role="tab"
                                className={
                                  verticalTabs === "documents" ? "active" : ""
                                }
                                onClick={() => setVerticalTabs("documents")}
                              >
                                Documents
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>
                    </Col>
                    <Col lg="10" md="7" sm="8" xs="6">
                      {/* Tab panes */}
                      <TabContent activeTab={verticalTabs}>
                        <TabPane tabId="personal-details">
                          <Row>
                            <Col className="ml-auto mr-auto" lg="6">
                              <Row>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Title</label>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      name="title"
                                      value={ClientTitle}
                                      onChange={(e) => {
                                        setClientTitle(e);
                                      }}
                                      options={titleOptions}
                                      defaultValue={ClientTitle}
                                      placeholder="Title"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>First Name</label>
                                    <Input
                                      type="text"
                                      value={ClientFirstName || ""}
                                      onChange={(e) => {
                                        setClientFirstName(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Last Name</label>
                                    <Input
                                      type="text"
                                      value={ClientLastName || ""}
                                      onChange={(e) => {
                                        setClientLastName(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="4">
                                  <label>DOB</label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    onChange={(e) => {
                                      setBirthDay(e);
                                    }}
                                    options={dayOptions}
                                    defaultValue={BirthDay}
                                    placeholder="DD"
                                  />
                                </Col>
                                <Col className="ml-auto mr-auto" lg="4">
                                  <label>&nbsp;</label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    onChange={(e) => {
                                      setBirthMonth(e);
                                    }}
                                    options={monthOptions}
                                    placeholder="MM"
                                    defaultValue={BirthMonth}
                                  />
                                </Col>
                                <Col className="ml-auto mr-auto" lg="4">
                                  <label>&nbsp;</label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    onChange={(e) => {
                                      setBirthYear(e);
                                    }}
                                    options={yearOptions}
                                    placeholder="YYYY"
                                    defaultValue={BirthYear}
                                  />
                                </Col>
                                <Col className="ml-auto mr-auto mt-2" lg="12">
                                  <label>Gender</label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={ClientGender}
                                    onChange={(e) => {
                                      setClientGender(e);
                                    }}
                                    defaultValue={ClientGender}
                                    options={genderOptions}
                                    placeholder="Select Gender"
                                  />
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <label>Nationality</label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={ClientNationality}
                                    onChange={(e) => {
                                      setClientNationality(e);
                                    }}
                                    defaultValue={ClientNationality}
                                    options={countryOptions}
                                    placeholder="Select Nationality"
                                  />
                                </Col>
                                {/* {opportunities_Status_value ==
                                "Opportunity Squandered" ? (
                                  <>
                                    <Col className="ml-auto mr-auto" lg="4">
                                      <label>Follow Up Date</label>
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="title"
                                        onChange={(e) => {
                                          setFollowUpDay(e);
                                        }}
                                        options={dayOptions}
                                        defaultValue={FollowUpDay}
                                        placeholder="DD"
                                      />
                                    </Col>
                                    <Col className="ml-auto mr-auto" lg="4">
                                      <label>&nbsp;</label>
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="title"
                                        onChange={(e) => {
                                          setFollowUpMonth(e);
                                        }}
                                        options={monthOptions}
                                        placeholder="MM"
                                        defaultValue={FollowUpMonth}
                                      />
                                    </Col>
                                    <Col className="ml-auto mr-auto" lg="4">
                                      <label>&nbsp;</label>
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="title"
                                        onChange={(e) => {
                                          setFollowUpYear(e);
                                        }}
                                        options={followYearOptions}
                                        placeholder="YYYY"
                                        defaultValue={FollowUpYear}
                                      />
                                    </Col>
                                  </>
                                ) : null} */}
                                <Col className="ml-auto mr-auto mt-3" lg="6">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        value={1}
                                        defaultChecked={
                                          DoNotCall == 1 ? true : false
                                        }
                                        disabled={
                                          DoNotCall == 1 &&
                                          loggedUser.data.role !== 1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          const checked = e.target.checked;
                                          if (checked) {
                                            setDoNotCall(e.target.value);
                                          } else {
                                            setDoNotCall("");
                                          }
                                        }}
                                      />
                                      <span className="form-check-sign" />
                                      Do Not Call
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto mt-3" lg="6">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        value={1}
                                        defaultChecked={
                                          PensionOpportunity == 1 ? true : false
                                        }
                                        disabled={
                                          PensionOpportunity == 1 &&
                                          loggedUser.data.role !== 1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          const checked = e.target.checked;
                                          if (checked) {
                                            setPensionOpportunity(
                                              e.target.value
                                            );
                                          } else {
                                            setPensionOpportunity("");
                                          }
                                        }}
                                      />
                                      <span className="form-check-sign" />
                                      Pension Opportunity
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="ml-auto mr-auto" lg="6">
                              <Row>
                                <Col className="ml-auto mr-auto" lg="5">
                                  <FormGroup className={`has-label`}>
                                    <label>Primary Number</label>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      name="title"
                                      onChange={(e) => {
                                        setPrimaryCC(e.value);
                                      }}
                                      value={getSelectValue(
                                        PrimaryCC,
                                        dialCodeOptions
                                      )}
                                      options={dialCodeOptions}
                                      placeholder="Select Dialing code"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="7">
                                  <FormGroup className={`has-label`}>
                                    <label>&nbsp;</label>
                                    <Input
                                      type="text"
                                      value={PrimaryPhone || ""}
                                      onChange={(e) => {
                                        setPrimaryPhone(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="5">
                                  <FormGroup className={`has-label`}>
                                    <label>Secondary Number</label>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      name="title"
                                      onChange={(e) => {
                                        setSecondaryCC(e.value);
                                      }}
                                      value={getSelectValue(
                                        SecondaryCC,
                                        dialCodeOptions
                                      )}
                                      options={dialCodeOptions}
                                      placeholder="Select Dialing code"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="7">
                                  <FormGroup className={`has-label`}>
                                    <label>&nbsp;</label>
                                    <Input
                                      type="text"
                                      value={SecondaryPhone || ""}
                                      onChange={(e) => {
                                        setSecondaryPhone(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Email</label>
                                    <Input
                                      type="text"
                                      value={ClientEmail || ""}
                                      onChange={(e) => {
                                        setClientEmail(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Other Email</label>
                                    <Input
                                      type="text"
                                      value={ClientOtherEmail || ""}
                                      onChange={(e) => {
                                        setClientOtherEmail(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <FormGroup className={`has-label`}>
                                    <label>Address</label>
                                    <Input
                                      type="text"
                                      value={ClientAddress || ""}
                                      onChange={(e) => {
                                        setClientAddress(e.target.value);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="ml-auto mr-auto" lg="12">
                                  <label>Location</label>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="title"
                                    value={ClientLocation}
                                    onChange={(e) => {
                                      setClientLocation(e);
                                    }}
                                    options={countryOptions}
                                    placeholder="Select Nationality"
                                  />
                                </Col>
                                <Col className="" lg="12">
                                  <FormGroup
                                    className={`has-label  ${PremiumTypeState}`}
                                  >
                                    <label>Policy</label>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      name="title"
                                      onChange={(e) => {
                                        if (e.value === "") {
                                          setPremiumTypeState("has-danger");
                                        } else {
                                          setPremiumTypeState("has-success");
                                        }
                                        setPremiumType(e.value);
                                      }}
                                      value={PremiumType}
                                      options={[
                                        {
                                          value: "",
                                          label: "Premium type",
                                          isDisabled: true,
                                        },
                                        {
                                          value: "individual",
                                          label: "Individual",
                                        },
                                        {
                                          value: "corporate",
                                          label: "Corporate",
                                        },
                                      ]}
                                      placeholder="Select Policy"
                                    />
                                    {PremiumTypeState === "has-danger" ? (
                                      <label className="error text-danger">
                                        This field is required.
                                      </label>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="ml-auto mr-auto" lg="12">
                              <Button onClick={updateClient}>Save</Button>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="products">
                          <ClientProducts client_id={ClientId}></ClientProducts>
                        </TabPane>
                        <TabPane tabId="activities">
                          <ActivityList client_id={ClientId}></ActivityList>
                        </TabPane>
                        <TabPane tabId="documents">
                          <AddDoc client_id={ClientId}></AddDoc>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditLead;
