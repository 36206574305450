/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {
  statusOptions,
  productOptions,
  generatorOption,
  periodOption,
  currencyOption,
} from "variables/productOption";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { notify } from "components/functions";
import SubmitLoader from "components/SubmitLoader";
import PremiumDetails from "./PremiumDetails";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import { FormGroup, Input, Row, Col, Label, Table, Button } from "reactstrap";
import { GetIndividualStatusLogsApi } from "store/FetchOpportunities";

function EditProducts(props) {
  const notificationAlert = React.useRef();
  const [loadingData, setLoadingData] = React.useState(true);
  const [isCDMDisabled, setIsCDMDisabled] = useState(false);
  const [Status, setStatus] = useState("");
  const [Consultant, setConsultant] = useState("");
  const [Insurer, setInsurer] = useState("");
  const [Product, setProduct] = useState("");
  const [staffUsers, setStaffUsers] = useState("");
  const [CDMUser, setCDMUser] = useState("");
  const [consultantOptions, setconsultantOptions] = useState([]);
  const [staffUsersOptions, setStaffUsersOptions] = useState([]);
  const [CDMUsersOptions, setCDMUsersOptions] = useState([]);
  const [InsurerOptions, setInsurerOptions] = useState([]);
  const [RefrralOptions, setRefrralOptions] = useState([]);
  const [additional, setadditional] = useState([]);
  const [countryOption, setcountryOption] = useState([]);
  const [Generator, setGenerator] = useState("");
  const [PolicyNumber, setPolicyNumber] = useState("");
  const [Discount, setDiscount] = useState("");
  const [PremiumCurrency, setPremiumCurrency] = useState("");
  const [CommCurrency, setCommCurrency] = useState("");
  const [AnnualPremium, setAnnualPremium] = useState("");
  const [commission, setcommission] = useState("");
  const [Period, setPeriod] = useState("");
  const [alert, setAlert] = React.useState(null);
  const [totalComm, settotalComm] = useState("");
  const [payment, setPayment] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [notes, setNotes] = useState("");
  const [InitialPremiumPaid, setInitialPremiumPaid] = useState("");
  const getLeadUrl =
    process.env.REACT_APP_API_URL + "/get-product-detail/" + props.product_id;
  const updateProductUrl =
    process.env.REACT_APP_API_URL + "/update-product-detail";
  const loggedUser = JSON.parse(localStorage.getItem("current_users"));
  const [showLoader, setShowLoader] = React.useState(false);
  const [displayOption, setdisplayOption] = useState("d-none");
  const [gridSize, setgridSize] = useState("12");
  const [Refrral, setRefrral] = useState("");
  const [FollowUpDay, setFollowUpDay] = useState("");
  const [FollowUpMonth, setFollowUpMonth] = useState("");
  const [FollowUpYear, setFollowUpYear] = useState("");
  const [compareStatus, setCompareStatus] = useState("");
  const [individualStatusLogs, setIndividualStatusLogs] = useState([]);

  const dispatch = useDispatch();

  let statusOptionsArr;
  if(loggedUser.data.role === 10){
    statusOptionsArr = [
      { value: "Opportunity Closed", label: "Opportunity Closed" },
      { value: "Opportunity Reopened", label: "Opportunity Reopened" },
      { value: "Opportunity Squandered", label: "Opportunity Squandered" },
      { value: "Opportunity Squandered - Final", label: "Opportunity Squandered - Final" },
      { value: "Account Closed", label: "Account Closed" },
      { value: "Duplicate", label: "Duplicate" },
    ];
  }
  else{
    statusOptionsArr = statusOptions;
  }
  let paymentOptions = [];
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + loggedUser.token);
  for (let index = 1; index <= 48; index++) {
    paymentOptions.push({
      value: index + " payments",
      label: index + " payments",
    });
  }
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  let dayOptions = [];
  let monthOptions = [];
  let yearOptions = [];
  const date = new Date();
  let followYearOptions = [];

  for (let index = 1; index <= 31; index++) {
    dayOptions.push({ value: index, label: index });
  }
  for (let index = 1; index <= 12; index++) {
    monthOptions.push({ value: index, label: index });
  }
  for (
    let index = date.getFullYear();
    index <= date.getFullYear() + 10;
    index++
  ) {
    followYearOptions.push({ value: index, label: index });
  }

  const { IndividualStatusLogsDataSuccess } = useSelector(
    (state) => state.getIndividualStatusLogsDataRe
  );
  // console.log(
  //   "IndividualStatusLogsDataSuccess",
  //   IndividualStatusLogsDataSuccess
  // );

  useEffect(() => {
    if (IndividualStatusLogsDataSuccess?.data?.IndividualStatusData) {
      setIndividualStatusLogs(
        IndividualStatusLogsDataSuccess?.data?.IndividualStatusData
      );
    }
  }, [IndividualStatusLogsDataSuccess]);

  // console.log("individualStatusLogs", individualStatusLogs);

  const updateProduct = () => {
    let statuschange = "";
    // console.log("Status.value", Status?.value);
    // console.log("compareStatus", compareStatus?.value);
    if (Status?.value == compareStatus?.value) {
      statuschange = false;
    } else {
      statuschange = true;
    }
    var data = {
      status: Status.value,
      product: Product.value,
      insurer: Insurer.value,
      generator: Generator.value,
      policy_number: PolicyNumber,
      discount_rate: Discount,
      annual_premium: PremiumCurrency.value,
      annual_premium_amount: AnnualPremium,
      annual_premium_payment_period: Period.value,
      commission_rate: commission,
      total_commission_currency: CommCurrency.value,
      total_commission_amount: totalComm,
      total_commission_payment_period: payment.value,
      initial_premium_paid: InitialPremiumPaid,
      start_date: startDate,
      end_date: endDate,
      followup_day: FollowUpDay.value,
      followup_month: FollowUpMonth.value,
      followup_year: FollowUpYear.value,
      user_id: Consultant.value,
      staff_user_id: staffUsers.value,
      cdm_user_id: CDMUser?.value,
      addtional_person: additional,
      new_addtional_person: formValues,
      product_id: props.product_id,
      client_id: props.client_id,
      referral: !Refrral ? 0 : Refrral.value !== "" ? Refrral.value : 0,
      statuschange: statuschange,
      old_status: compareStatus?.value,
      notes: notes,
    };

    if (
      Status.value !== "" &&
      Product.value !== "" &&
      Insurer.value !== "" &&
      Generator.value !== "" &&
      Consultant.value != ""
    ) {
      setShowLoader(true);
      fetch(updateProductUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (result) {
          console.log(result);
          if (!result.success) {
            notify(result.message, 3, notificationAlert);
          } else {
            setCompareStatus({ value: data.status, label: data.status });
            notify(result.message, 2, notificationAlert);
          }
          setShowLoader(false);
        });
    } else {
      notify("Fields makerd with (*) are required.", 3, notificationAlert);
    }
  };

  useEffect(() => {
    async function getProductDetail() {
      fetch(getLeadUrl, {
        method: "GET",
        headers: headers,
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success) {
            console.log("datadatadatadata", data);
            const apiResponse = data.users;
            const sortedOptions = [...apiResponse].sort((a, b) => {
              if (a.label === "N/A") {
                return -1;
              }
              if (b.label === "N/A") {
                return 1;
              }

              return a.label.localeCompare(b.label);
            });
            const newSortedOptions = sortedOptions.filter((val) => {
              if (
                val.label === "N/A" ||
                val.label === "Claire Dimla" ||
                val.label === "Matthew Thomson" ||
                val.label === "Michael Smith" ||
                val.label === "Nim Hiran" ||
                val.label === "Onnapa Sangin" ||
                val.label === "Peach Nante" ||
                val.label === "Sue Soe" ||
                val.label === "Sunny Sakchira" ||
                val.label === "Tristan Slock" ||
                val.label === "Turk Rirksom" ||
                val.label === "Darren Wise" ||
                val.label === "Priya Ghogar" ||
                val.label == "Sally Skeggs" ||
                val.label == "Arjun Harhangi" ||
                val.label == "Monique Festejo" ||
                val.label == "Veshali Sehgal"
              ) {
                return val;
              }
            });
            // console.log("sortedOptions", sortedOptions);
            setconsultantOptions(newSortedOptions);

            console.log("data.staffUsers", data.staffUsers);
            const staffUsers = data.staffUsers;
            const staffUserssortedOptions = [...staffUsers].sort((a, b) => {
              if (a.label === "N/A") {
                return -1;
              }
              if (b.label === "N/A") {
                return 1;
              }

              return a.label.localeCompare(b.label);
            });

            const newStaffUserssortedOptions = staffUserssortedOptions.filter((val) => {
              if (
                val.label === "N/A" ||
                val.label === "Amy Singh" ||
                val.label === "Claire Dimla" ||
                val.label === "Matthew Thomson" ||
                val.label === "Michael Smith" ||
                val.label === "Nim Hiran" ||
                val.label === "Onnapa Sangin" ||
                val.label === "Peach Nante" ||
                val.label === "Sue Soe" ||
                val.label === "Sunny Sakchira" ||
                val.label === "Turk Rirksom" ||
                val.label === "Priya Ghogar" ||
                val.label === "Varissara Onsri" ||
                val.label == "Sally Skeggs"
              ) {
                return val;
              }
            });
            const CDMUsers = data.CDMUsers;
            const CDMUserssortedOptions = [{ value: "", label: "N/A" }, ...CDMUsers.sort((a, b) => a.label.localeCompare(b.label))];
            setCDMUsersOptions(CDMUserssortedOptions);
            setCDMUser(CDMUserssortedOptions.find(option => option.value === data.product.cdm_user_id));
            setIsCDMDisabled(
              loggedUser.data.role === 10
                ? data.product.status !== "Opportunity Reopened"
                : !["Opportunity Squandered", "Opportunity Open", "Account Pending"].includes(data.product.status)
            );
            setNotes(data.product.notes);
            // setconsultantOptions(data.users);
            // setStaffUsersOptions(data.staffUsers);
            setStaffUsersOptions(newStaffUserssortedOptions);
            setInsurerOptions(data.insurer);
            setcountryOption(data.countries);
            setadditional(data.additional);
            setRefrralOptions(data.referrals);
            setRefrral(
              data.product.referral !== null
                ? data.referrals[data.product.referral]
                : { value: "", label: "Referral" }
            );
            setStatus(
              data.product.status !== null
                ? { value: data.product.status, label: data.product.status }
                : { value: "", label: "Status" }
            );
            setCompareStatus(
              data.product.status !== null
                ? { value: data.product.status, label: data.product.status }
                : { value: "", label: "Status" }
            );
            setConsultant(
              data.product.user_id !== null
                ? { value: data.product.user_id, label: data.product.user }
                : { value: "", label: "Consultant" }
            );
            setStaffUsers(
              data.product.staff_user_id !== null
                ? {
                    value: data.product.staff_user_id,
                    label: data.product.staff_user,
                  }
                : { value: "", label: "Support Manager" }
            );

            setInsurer(
              data.product.insurer !== null
                ? {
                    value: data.product.insurer,
                    label: data.product.insurer_name,
                  }
                : { value: "", label: "Insurer" }
            );
            setProduct(
              data.product.product !== null
                ? { value: data.product.product, label: data.product.product }
                : { value: "", label: "Product" }
            );
            setGenerator(
              data.product.generator !== null
                ? {
                    value: data.product.generator,
                    label: data.product.generator,
                  }
                : { value: "", label: "Generator" }
            );
            setPolicyNumber(data.product["policy-number"]);
            setDiscount(data.product["discount-rate"]);
            setPremiumCurrency(
              data.product["annual-premium"] !== null
                ? {
                    value: data.product["annual-premium"],
                    label: data.product["annual-premium"],
                  }
                : { value: "", label: "Currency" }
            );
            setCommCurrency(
              data.product["total-commission-currency"] !== null
                ? {
                    value: data.product["total-commission-currency"],
                    label: data.product["total-commission-currency"],
                  }
                : { value: "", label: "Currency" }
            );
            setAnnualPremium(data.product["annual-premium-amount"]);
            setPeriod(
              data.product["annual-premium-payment-period"] !== null
                ? {
                    value: data.product["annual-premium-payment-period"],
                    label: data.product["annual-premium-payment-period"],
                  }
                : { value: "", label: "Period" }
            );
            setcommission(data.product["commission-rate"]);
            settotalComm(data.product["total-commission-amount"]);
            setPayment(
              data.product["total-commission-payment-period"] !== null
                ? {
                    value: data.product["total-commission-payment-period"],
                    label: data.product["total-commission-payment-period"],
                  }
                : { value: "", label: "Payment" }
            );
            setstartDate(data.product["start-date"]);
            setendDate(data.product["end-date"]);

            setFollowUpDay(
              data.product["followup-day"] !== null
                ? {
                    value: data.product["followup-day"],
                    label: data.product["followup-day"],
                  }
                : { value: "", label: "DD", isDisabled: true }
            );
            setFollowUpMonth(
              data.product["followup-month"] !== null
                ? {
                    value: data.product["followup-month"],
                    label: data.product["followup-month"],
                  }
                : { value: "", label: "MM", isDisabled: true }
            );
            setFollowUpYear(
              data.product["followup-year"] !== null
                ? {
                    value: data.product["followup-year"],
                    label: data.product["followup-year"],
                  }
                : { value: "", label: "YYYY", isDisabled: true }
            );
            setInitialPremiumPaid(data.product["initial-premium-paid"]);

            if (data.product.generator === "Referral") {
              setgridSize(6);
              setdisplayOption("d-block");
            }
            setLoadingData(false);
          } else {
          }
        });
    }
    const getIndividualStatusLogData = async () => {
      await dispatch(
        GetIndividualStatusLogsApi(props.product_id, loggedUser.token)
      );
    };
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getProductDetail();
      getIndividualStatusLogData();
    }
  }, []);

  const deleteAddtionalUser = (user_id, product_id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(user_id, product_id)}
        onCancel={() => hideAlert("no")}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this!
      </ReactBSAlert>
    );
  };
  const successDelete = (user_id, product_id) => {
    const deleteInsurerUrl =
      process.env.REACT_APP_API_URL + "/delete-addtional-person";
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + loggedUser.token);
    fetch(deleteInsurerUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ user_id: user_id, product_id: product_id }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.success) {
        } else {
          setadditional(data.additional);
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Deleted!"
              onConfirm={() => hideAlert("no-refresh")}
              onCancel={() => hideAlert("no-refresh")}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              {data.message}
            </ReactBSAlert>
          );
        }
      });
  };
  const hideAlert = (mode) => {
    setAlert(null);
    if (mode === "refresh") {
      window.location.reload();
    }
  };
  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, fieldState) => {
    if (value.length >= 1) {
      fieldState("has-success");
    } else {
      fieldState("has-danger");
    }
  };

  const [formValues, setFormValues] = useState([
    {
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      rStatus: "",
      nationality: "",
    },
  ]);

  let handleExistingChange = (i, e, type, name = "") => {
    let newFormValues = [...additional];
    if (type === "select") {
      newFormValues[i][name] = e.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    setadditional(newFormValues);
  };

  let handleChange = (i, e, type, name = "") => {
    let newFormValues = [...formValues];
    if (type === "select") {
      newFormValues[i][name] = e.value;
    } else if (type === "date") {
      newFormValues[i][name] = moment(e.toDate()).format("DD-MM-YYYY");
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }

    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        rStatus: "",
        nationality: "",
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  return (
    <>
      <SubmitLoader showLoader={showLoader} />
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
            {alert}
            {loadingData ? (
              <p>Loading Please wait...</p>
            ) : (
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <Row>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Status*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setStatus(e);
                            setIsCDMDisabled(
                              loggedUser.data.role === 10
                                ? e.value !== "Opportunity Reopened"
                                : !["Opportunity Squandered", "Opportunity Open", "Account Pending"].includes(e.value)
                            );
                          }}
                          options={statusOptionsArr}
                          defaultValue={Status}
                          placeholder="Status"
                        />
                      </FormGroup>
                    </Col>
                    {/* {Status?.value == "Opportunity Squandered" ? ( */}
                    <>
                      <Col className="ml-auto mr-auto" lg="4">
                        <label>Follow Up Date</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="title"
                          onChange={(e) => {
                            setFollowUpDay(e);
                          }}
                          options={dayOptions}
                          placeholder="DD"
                          defaultValue={FollowUpDay}
                        />
                      </Col>
                      <Col className="ml-auto mr-auto" lg="4">
                        <label>&nbsp;</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="title"
                          onChange={(e) => {
                            setFollowUpMonth(e);
                          }}
                          options={monthOptions}
                          placeholder="MM"
                          defaultValue={FollowUpMonth}
                        />
                      </Col>
                      <Col className="ml-auto mr-auto" lg="4">
                        <label>&nbsp;</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="title"
                          onChange={(e) => {
                            setFollowUpYear(e);
                          }}
                          options={followYearOptions}
                          placeholder="YYYY"
                          defaultValue={FollowUpYear}
                        />
                      </Col>
                    </>
                    {/* // ) : null} */}
                    {/* staffUsersOptions */}
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Client Relationship Manager*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setConsultant(e);
                          }}
                          options={consultantOptions}
                          defaultValue={Consultant}
                          placeholder="Select Consultant"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Support Manager*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setStaffUsers(e);
                          }}
                          options={staffUsersOptions}
                          defaultValue={staffUsers}
                          placeholder="Support Manager"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Insurer*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setInsurer(e);
                          }}
                          options={InsurerOptions}
                          defaultValue={Insurer}
                          placeholder="Select Provider"
                        />
                      </FormGroup>
                    </Col>
                    {individualStatusLogs.map((user) => (
                      <Col
                        className="ml-auto mr-auto"
                        style={{ marginBottom: -36 }}
                        md="12"
                      >
                        <br />
                        <div
                          className="username-info"
                          style={{ display: "flex" }}
                        >
                          <label style={{ fontWeight: 505 }}>
                            Updated By:{" "}
                          </label>
                          &nbsp;&nbsp;{" "}
                          <p style={{ color: "#007bff", fontWeight: 505 }}>
                            {user.user_name}
                          </p>
                          &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                          <label style={{ fontWeight: 505 }}>
                            Old Status:{" "}
                          </label>
                          &nbsp;&nbsp;{" "}
                          <p style={{ color: "#007bff", fontWeight: 505 }}>
                            {user.old_status}
                          </p>
                          &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                          <label style={{ fontWeight: 505 }}>
                            Updated At:{" "}
                          </label>
                          &nbsp;&nbsp;{" "}
                          <p style={{ color: "#007bff", fontWeight: 505 }}>
                            {user.created_at}
                          </p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col className="ml-auto mr-auto" md="6">
                  <Row>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Product*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setProduct(e);
                          }}
                          options={productOptions}
                          defaultValue={Product}
                          placeholder="Select"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md={`${gridSize}`}>
                      <FormGroup className={`has-label`}>
                        <label>Generator*</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setGenerator(e);
                            if (e.value === "Referral") {
                              setgridSize(6);
                              setdisplayOption("d-block");
                              setRefrral({ value: "", label: "" });
                            } else {
                              setgridSize(12);
                              setdisplayOption("d-none");
                            }
                          }}
                          options={generatorOption}
                          defaultValue={Generator}
                          placeholder="Select"
                        />
                      </FormGroup>
                    </Col>
                    <Col className={`ml-auto mr-auto ${displayOption}`} md="6">
                      <FormGroup className={`has-label`}>
                        <label>Referral</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setRefrral(e);
                          }}
                          options={Object.values(RefrralOptions)}
                          defaultValue={Refrral}
                          placeholder="Select"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Policy No.</label>
                        <Input
                          type="text"
                          value={PolicyNumber || ""}
                          onChange={(e) => {
                            setPolicyNumber(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                      <FormGroup className={`has-label`}>
                        <label>Client Development Manager</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            setCDMUser(e);
                          }}
                          options={CDMUsersOptions}
                          defaultValue={CDMUser}
                          placeholder="Client Development Manager"
                          isDisabled={isCDMDisabled}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md="12" style={{ marginBottom: 10 }} className="modal-header justify-content-center">
                <h4 className="title title-up">Premium Details</h4>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Discount(%)</label>
                    <Input
                      type="number"
                      value={Discount || ""}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Premium Currency</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setPremiumCurrency(e);
                      }}
                      options={currencyOption}
                      defaultValue={PremiumCurrency}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Annual Premium</label>
                    <Input
                      type="number"
                      value={AnnualPremium || ""}
                      onChange={(e) => {
                        setAnnualPremium(e.target.value);
                        if (commission != "" && e.target.value != "") {
                          settotalComm(
                            (e.target.value * (commission / 100)).toFixed(2)
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Premium Frequency</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setPeriod(e);
                      }}
                      options={periodOption}
                      defaultValue={Period}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>

                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Comm(%)</label>
                    <Input
                      type="number"
                      value={commission || ""}
                      onChange={(e) => {
                        setcommission(e.target.value);
                        if (e.target.value != "" && AnnualPremium != "") {
                          settotalComm(
                            (AnnualPremium * (e.target.value / 100)).toFixed(2)
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Commission Currency</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setCommCurrency(e);
                      }}
                      options={currencyOption}
                      defaultValue={CommCurrency}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Total Commission</label>
                    <Input
                      type="number"
                      value={totalComm || ""}
                      readOnly={true}
                      placeholder="[Calculation: (Annual Premium) x (Comm(%))]"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={`has-label`}>
                    <label>Period</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setPayment(e);
                      }}
                      options={paymentOptions}
                      defaultValue={payment}
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Start Date</label>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        value: startDate || "",
                      }}
                      // isValidDate={disablePastDt}
                      timeFormat={false}
                      onChange={(e) => {
                        setstartDate(moment(e.toDate()).format("DD-MM-YYYY"));
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>End Date</label>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        value: endDate || "",
                      }}
                      // isValidDate={disablePastDt}
                      timeFormat={false}
                      onChange={(e) => {
                        setendDate(moment(e.toDate()).format("DD-MM-YYYY"));
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={1}
                        defaultChecked={InitialPremiumPaid == 1 ? true : false}
                        disabled={
                          loggedUser.data.role === 1 ||
                          loggedUser.data.role === 2
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            setInitialPremiumPaid(e.target.value);
                          } else {
                            setInitialPremiumPaid("");
                          }
                        }}
                      />
                      <span className="form-check-sign" />
                      Initial premium paid
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <hr className="m-3" />
                </Col>

                <Col md="12">
                  <Table className="table">
                    <thead>
                      <tr>
                        <th width="12%">First Name</th>
                        <th width="12%">Last Name</th>
                        <th width="12%">Gender</th>
                        <th width="12%">DOB</th>
                        <th width="20%">Location</th>
                        <th width="20%">Nationality</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {additional.length > 0
                        ? additional.map((element, index) => (
                            <tr key={additional[index]["id"] + "-" + index}>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Input
                                    type="text"
                                    name="first-name"
                                    value={
                                      additional[index]["first-name"] || ""
                                    }
                                    onChange={(e) =>
                                      handleExistingChange(index, e, "input")
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Input
                                    type="text"
                                    name="last-name"
                                    value={additional[index]["last-name"] || ""}
                                    onChange={(e) =>
                                      handleExistingChange(index, e, "input")
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Select
                                    name="gender"
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={[
                                      { value: "male", label: "male" },
                                      { value: "female", label: "female" },
                                    ]}
                                    placeholder="Select"
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "select",
                                        "gender"
                                      )
                                    }
                                    defaultValue={
                                      additional[index]["gender"] !== null
                                        ? {
                                            value: additional[index]["gender"],
                                            label: additional[index]["gender"],
                                          }
                                        : { value: "", label: "Gender" }
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup>
                                  <ReactDatetime
                                    inputProps={{
                                      className: "form-control",
                                      placeholder: "Date Picker Here",
                                      value: additional[index]["date"] || "",
                                      name: "date",
                                    }}
                                    timeFormat={false}
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "date",
                                        "date"
                                      )
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Select
                                    name="rStatus"
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={countryOption}
                                    placeholder="Select"
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "select",
                                        "r-status"
                                      )
                                    }
                                    defaultValue={
                                      additional[index]["r-status"] !== null
                                        ? {
                                            value:
                                              additional[index]["r-status"],
                                            label:
                                              additional[index]["r-status"],
                                          }
                                        : { value: "", label: "R Status" }
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <FormGroup className={`has-label`}>
                                  <Select
                                    name="nationality"
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={countryOption}
                                    placeholder="Select"
                                    onChange={(e) =>
                                      handleExistingChange(
                                        index,
                                        e,
                                        "select",
                                        "nationality"
                                      )
                                    }
                                    defaultValue={
                                      additional[index]["nationality"] !== null
                                        ? {
                                            value:
                                              additional[index]["nationality"],
                                            label:
                                              additional[index]["nationality"],
                                          }
                                        : { value: "", label: "Nationality" }
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                {loggedUser.data.role === 1 ||
                                loggedUser.data.role === 2 ? (
                                  <Button
                                    color="danger"
                                    className="button remove"
                                    onClick={() =>
                                      deleteAddtionalUser(
                                        additional[index]["id"],
                                        additional[index]["product_id"]
                                      )
                                    }
                                  >
                                    Remove
                                  </Button>
                                ) : null}
                              </td>
                            </tr>
                          ))
                        : null}
                      {formValues.map((element, index) => (
                        <tr key={index}>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Input
                                type="text"
                                name="firstName"
                                value={element.firstName || ""}
                                onChange={(e) =>
                                  handleChange(index, e, "input")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Input
                                type="text"
                                name="lastName"
                                value={element.lastName || ""}
                                onChange={(e) =>
                                  handleChange(index, e, "input")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Select
                                name="gender"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                options={[
                                  { value: "male", label: "male" },
                                  { value: "female", label: "female" },
                                ]}
                                placeholder="Select"
                                onChange={(e) =>
                                  handleChange(index, e, "select", "gender")
                                }
                                value={{
                                  value: element.gender,
                                  label: element.gender,
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  name: "dob",
                                }}
                                timeFormat={false}
                                onChange={(e) =>
                                  handleChange(index, e, "date", "dob")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Select
                                name="rStatus"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                options={countryOption}
                                placeholder="Select"
                                onChange={(e) =>
                                  handleChange(index, e, "select", "rStatus")
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className={`has-label`}>
                              <Select
                                name="nationality"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                options={countryOption}
                                placeholder="Select"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    e,
                                    "select",
                                    "nationality"
                                  )
                                }
                              />
                            </FormGroup>
                          </td>
                          <td>
                            {index === formValues.length - 1 ? (
                              <Button
                                color="primary"
                                onClick={() => addFormFields()}
                              >
                                Add More
                              </Button>
                            ) : (
                              <Button
                                color="danger"
                                className="button remove"
                                onClick={() => removeFormFields(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <div className="modal-header justify-content-center">
          <h4 className="title title-up">Commission Details</h4>
        </div>

        <PremiumDetails
          product_id={props.product_id}
          client_id={props.client_id}
        ></PremiumDetails>
        {loggedUser.data.role === 1 ||
        loggedUser.data.role === 2 ? (
        <>
        <Col md="12" style={{ marginBottom: 10 }} className="modal-header justify-content-center">
          <h4 className="title title-up">Notes</h4>
        </Col>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <FormGroup className={`has-label`}>
              <label>Description</label>
              <Input
                type="textarea"
                id="notes"
                placeholder="Enter your notes..."
                rows="4"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        </>
      ) : null}
      </div>

      {loggedUser.data.role === 1 ||
      loggedUser.data.role === 2 ||
      loggedUser.data.role === 3 ||
      loggedUser.data.role === 5 ||
      loggedUser.data.role === 8 ||
      loggedUser.data.role === 10 ||
      loggedUser.data.role === 7 ||
      loggedUser.data.role === 6 ? (
        <Col className="text-center ml-auto mr-auto" md="12">
          <Button color="primary" onClick={updateProduct}>
            Save
          </Button>
        </Col>
      ) : null}
    </>
  );
}

export default EditProducts;
