import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import Opportunities from "components/Leads/Opportunities";

function BulkReassign() {
  

  return (

    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Bulk Reassign Tool</CardTitle>
            </CardHeader>
            <CardBody>
              <Opportunities api="bulk-assign-leads" leadType="bulk-assign" method="GET" filterData=""></Opportunities>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );


}

export default BulkReassign;
