import axios from "axios";
import * as groupOpportunityAction from "./GroupLeadOpportunitiesAction";

const URL = process.env.REACT_APP_API_URL;
// const URL = "https://stg-api.i-brokers.com/api";

export const addGroupOpportunity = (senddata, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(
      `${URL}/create-new-group-opportunities`,
      senddata,
      {
        headers: headers,
      }
    );
    // console.log("checking the addGroupOpportunity", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.ADD_GROUP_OPPORTUNITIES_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    // console.log("error", error.message);
    dispatch({
      type: groupOpportunityAction.ADD_GROUP_OPPORTUNITIES_FAIELD,
      payload: error.message,
    });
  }
};

export const getSingleGroupLeadDataAPI =
  (hittingUrl, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      // console.log("senddata" ,senddata)
      const { data } = await axios.get(`${URL}/${hittingUrl}`, {
        headers: headers,
      });

      // console.log("checking the api", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_SINGLE_LEAD_DATA_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      // console.log("error", error.message);
      dispatch({
        type: groupOpportunityAction.GET_SINGLE_LEAD_DATA_FAIELD,
        payload: error.message,
      });
    }
  };

export const UpdateGroupDataApi = (updateData, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    // console.log("senddata" ,senddata)
    const { data } = await axios.post(`${URL}/update-group`, updateData, {
      headers: headers,
    });

    // console.log("checking the UpdateGroupDataApi", data);
    if (data) {
      dispatch({
        type: groupOpportunityAction.UPADTE_GROUP_DATA_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    // console.log("error", error.message);
    dispatch({
      type: groupOpportunityAction.UPADTE_GROUP_DATA_FAIELD,
      payload: error.message,
    });
  }
};

export const FetchGroupProductApi = (group_id, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.get(
      `${URL}/get-group-products/${group_id}`,

      {
        headers: headers,
      }
    );

    // console.log("checking the FetchGroupProductApi", data);
    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_GROUP_PRODUCT_DATA_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    // console.log("error", error.message);
    dispatch({
      type: groupOpportunityAction.GET_GROUP_PRODUCT_DATA_FAIELD,
      payload: error.message,
    });
  }
};

export const FetchAllOptionApi = (token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.get(`${URL}/get-options`, {
      headers: headers,
    });

    // console.log("checking the FetchAllOptionApi", data);
    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_ALL_OPTIONS_DATA_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    // console.log("error", error.message);
    dispatch({
      type: groupOpportunityAction.GET_ALL_OPTIONS_DATA_FAIELD,
      payload: error.message,
    });
  }
};

export const InsertAddGroupProductApi =
  (GroupProductdata, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(
        `${URL}/add-group-product-detail/`,
        GroupProductdata,
        {
          headers: headers,
        }
      );

      // console.log("checking the InsertAddProductApi", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.POST_ADD_GROUP_PRODUCT_DATA_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      // console.log("error", error.message);
      dispatch({
        type: groupOpportunityAction.POST_ADD_GROUP_PRODUCT_DATA_FAIELD,
        payload: error.message,
      });
    }
  };

export const FetchSingleGroupProductDetailsApi =
  (productId, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.get(
        `${URL}/get-group-product-detail/${productId}`,
        { headers: headers }
      );
      // console.log("checking the FetchGroupProductDetailsApi", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_SINGLE_GROUP_PRODUCT_DATA_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_SINGLE_GROUP_PRODUCT_DATA_FAIELD,
        payload: error.message,
      });
    }
  };

export const UpdateGRoupProductApi =
  (updatedata, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(
        `${URL}/update-group-product-detail`,
        updatedata,
        { headers: headers }
      );
      // console.log("checking the UpdateGRoupProductApi", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_UPDATE_GROUP_PRODUCT_DATA_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_UPDATE_GROUP_PRODUCT_DATA_FAIELD,
        payload: error.message,
      });
    }
  };

export const deleteGroupAdditionalPersonApi =
  (deleteData, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(
        `${URL}/delete-group-addtional-person`,
        deleteData,
        { headers: headers }
      );
      // console.log("checking the deleteGroupAdditionalPersonApi", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_DELETE_ADDITIONAL_GROUP_DATA_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_DELETE_ADDITIONAL_GROUP_DATA_FAIELD,
        payload: error.message,
      });
    }
  };

export const AddPremiumDetailApi = (premiumData, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(
      `${URL}/add-group-premium-detail`,
      premiumData,
      { headers: headers }
    );
    // console.log("checking the AddPremiumDetailApi", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_ADD_GROUP_PREMIUMM_DETAILS_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_ADD_GROUP_PREMIUMM_DETAILS_FAIELD,
      payload: error.message,
    });
  }
};

export const DeleteGroupPremiumDetailApi =
  (premiumData, token) => async (dispatch) => {
    const id = { premium_detail_id: premiumData };
    // console.log("checking the DeleteGroupPremiumDetailApi", premiumData);

    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(
        `${URL}/delete-group-premium-detail`,
        id,
        { headers: headers }
      );

      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_DELETE_GROUP_PREMIUMM_DETAILS_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_DELETE_GROUP_PREMIUMM_DETAILS_FAIELD,
        payload: error.message,
      });
    }
  };

export const FetchAllGroupPremiumDetailsApi =
  (id, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.get(
        `${URL}/get-group-premium-detail/${id}`,
        { headers: headers }
      );
      // console.log("checking the FetchAllGroupPremiumDetailsApi", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      // console.log("checking the error.message", error.message);

      dispatch({
        type: groupOpportunityAction.GET_ALL_FETCH_GROUP_PREMIUMM_DETAILS_FAIELD,
        payload: error.message,
      });
    }
  };

export const FetchAllActivityApi = (id, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.get(`${URL}/get-group-activities/${id}`, {
      headers: headers,
    });
    // console.log("checking the FetchAllActivityApi", data);
    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_ALL_GROUP_FETCH_ACTIVITY_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    // console.log("checking the error.message", error.message);

    dispatch({
      type: groupOpportunityAction.GET_ALL_GROUP_FETCH_ACTIVITY_FAIELD,
      payload: error.message,
    });
  }
};

export const AddGroupActivityApi =
  (ActivityData, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(
        `${URL}/save-group-activity`,
        ActivityData,
        { headers: headers }
      );
      // console.log("checking the AddGroupActivityApi", data);

      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_ADD_GROUP_ACTIVITY_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_ADD_GROUP_ACTIVITY_FAIELD,
        payload: error.message,
      });
    }
  };

///////////////////////////////////////     group documents ////////////////////////////////

export const AddGroupDocumentApi =
  (DocumentData, token) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.post(`${URL}/save-group-doc`, DocumentData, {
        headers: headers,
      });
      // console.log("checking the AddGroupDocumentApi", data);

      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_ADD_GROUP_DOCUMENT_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: groupOpportunityAction.GET_ADD_GROUP_DOCUMENT_FAIELD,
        payload: error.message,
      });
    }
  };

export const FetchAllDocumentApi = (id, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.get(`${URL}/get-group-doc/${id}`, {
      headers: headers,
    });
    // console.log("checking the FetchAllDocumentApi", data);
    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_ALL_GROUP_FETCH_DOCUMENT_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    // console.log("checking the error.message", error.message);

    dispatch({
      type: groupOpportunityAction.GET_ALL_GROUP_FETCH_DOCUMENT_FAIELD,
      payload: error.message,
    });
  }
};

export const DeleteGroupDocumentApi = (id, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.get(`${URL}/delete-group-doc/${id}`, {
      headers: headers,
    });
    // console.log("checking the DeleteGroupDocumentApi", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_DELETE_GROUP_DOCUMENT_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_DELETE_GROUP_DOCUMENT_FAIELD,
      payload: error.message,
    });
  }
};

export const DeleteGroupOpportunityApi = (id, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(`${URL}/delete-group-leads`, id, {
      headers: headers,
    });
    // console.log("checking the DeleteGroupOpportunityApi", data);

    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_DELETE_GROUP_OPPORTUNITY_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    dispatch({
      type: groupOpportunityAction.GET_DELETE_GROUP_OPPORTUNITY_FAIELD,
      payload: error.message,
    });
  }
};

//

export const getAllProductCount = (fetchdata, token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const { data } = await axios.post(
      `${URL}/get-medical-products-count`,
      fetchdata,
      {
        headers: headers,
      }
    );
    // console.log("checking the getAllProductCount", data);
    if (data) {
      dispatch({
        type: groupOpportunityAction.GET_ALL_PRODUCT_COUNT_SUCCESS,
        payload: { data: data },
      });
    }
  } catch (error) {
    // console.log("checkin/g the error.message", error.message);

    dispatch({
      type: groupOpportunityAction.GET_ALL_PRODUCT_COUNT_FAIELD,
      payload: error.message,
    });
  }
};

export const FetchGroupMemberOptionApi =
  (token, group_id) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      const { data } = await axios.get(
        `${URL}/get-group-members/${group_id}`,
        {
          headers: headers,
        }
      );
      // console.log("checking the getAllProductCount", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_GROUP_MEMBERS_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      // console.log("checkin/g the error.message", error.message);

      dispatch({
        type: groupOpportunityAction.GET_GROUP_MEMBERS_FAIELD,
        payload: error.message,
      });
    }
  };



  export const DeleteProductsApi = (token, product_id) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      
      const { data } = await axios.post(`${URL}/delete-products-data`, {product_id:product_id} , {
        headers: headers,
      });
      // console.log("checking the getAllProductCount", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_PRODUCT_DELETE_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      // console.log("checkin/g the error.message", error.message);
  
      dispatch({
        type: groupOpportunityAction.GET_PRODUCT_DELETE_FAIELD,
        payload: error.message,
      });
    }
  };



  export const DeleteGroupProductsApi = (token, product_id) => async (dispatch) => {
    try {
      const headers = {
        Authorization: "Bearer " + token,
      };
      
      const { data } = await axios.post(`${URL}/delete-group-products-data`, {product_id:product_id} , {
        headers: headers,
      });
      // console.log("checking the getAllProductCount", data);
      if (data) {
        dispatch({
          type: groupOpportunityAction.GET_GROUP_PRODUCT_DELETE_SUCCESS,
          payload: { data: data },
        });
      }
    } catch (error) {
      // console.log("checkin/g the error.message", error.message);
  
      dispatch({
        type: groupOpportunityAction.GET_GROUP_PRODUCT_DELETE_FAIELD,
        payload: error.message,
      });
    }
  };
